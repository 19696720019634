<template>
  <div class="font-test">
    <div class="nav-box">
      <div class="top-menu-box" style="width: 1000px; margin: 0 auto">
        <div class="container top-menu">
          <!-- 左边logo -->
          <div class="left-logo">
            <el-image :src="logo" class="logo-img" lazy @click="pageHandle('/home')"></el-image>
          </div>
          <!-- 右边菜单 -->
        </div>
      </div>
    </div>
    <div class="content-box">
      <!-- <div class="demo-image__error"> -->
        <el-image src="/PC.jpg" class="header" lazy @load="imgOnload">
          <div slot="placeholder" v-if="showBtn" class="loading">
            {{ $t("加载中") }}<span class="dot">...</span>
          </div>
        </el-image>
      <!-- </div> -->
      <div class="header-href">
        <a  href="http://onelink.to/xyx76x"  class="main-fb"  target="_blank"></a>
      </div>
      <div class="main-box">
        <a href="https://www.facebook.com/travelcontents" class="main-FB" target="_blank"></a>
        <a href="https://www.instagram.com/travelcontents.tw/" class="main-IG" target="_blank"></a>
        <a href="https://www.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q/featured/" class="main-YT" target="_blank"></a>
        <a href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" class="apple" target="_blank"></a>
        <a v-if="language != 'zh'" href="https://play.google.com/store/apps/details?id=com.tripellet.app" class="google" target="_blank"></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showBtn: false,
      logo: require("@/assets/images/customer/PC-home.png"),
      // header: require("@/assets/images/draw/PC2.png"),
    };
  },
  computed: {
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  created(){
    this.imgOnload
  },
  mounted() {
    // 进入页面回到顶部
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } });
      } else {
        this.$router.push(path);
      }
    },
    imgOnload() {
      this.showBtn = true;
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.font-test {
  font-family: "GenJyuuGothic";
}
.app-container {
  width: 90%;
  margin: 0 auto;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
}
.button-box {
  position: absolute;
  top: 62.2%;
  left: 52.8%;
  z-index: 999;
  .el-image {
    width: 19%;
    margin: 0 5px;
  }
}
.nav-box {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}
.top-menu-box {
  background: #fff;
}
.logo-img {
  width: 30px;
  cursor: pointer;
}
.header {
  width: 100%;
  display: block;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.loading{
  font-size: 80px;
  color: #ff8b21;
  margin: 20% auto;
  text-align: center;
}
@media screen and (min-width: 1441px) and (max-width: 1920px) {
  .main-fb {
    position: absolute;
    left: 37%;
    top: 28.7%;
    width: 130px;
    height: 135px;
    display: block;
  }
  .main-FB {
    position: absolute;
    bottom: 13%;
    left: 42%;
    width: 80px;
    height: 72px;
    display: block;
  }
  .main-IG {
    position: absolute;
    bottom: 13%;
    left: 48%;
    width: 80px;
    height: 72px;
    display: block;
  }
  .main-YT {
    position: absolute;
    bottom: 13%;
    left: 54%;
    width: 80px;
    height: 72px;
    display: block;
  }
  .apple {
    position: absolute;
    left: 43.2%;
    bottom: 148px;
    width: 171px;
    height: 50px;
  }
  .google {
    position: absolute;
    left: 53.2%;
    bottom: 148px;
    width: 171px;
    height: 50px;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .content-box {
    position: relative;
    width: 100%;
    height: auto;
  }
  .main-fb {
    position: absolute;
    left: 36.5%;
    top: 28.6%;
    width: 60px;
    height: 60px;
    display: block;
  }
  .main-FB {
    position: absolute;
    bottom: 12.8%;
    left: 41%;
    width: 40px;
    height: 40px;
    display: block;
  }
  .main-IG {
    position: absolute;
    bottom: 12.8%;
    left: 47.5%;
    width: 40px;
    height: 40px;
    display: block;
  }
  .main-YT {
    position: absolute;
    bottom: 12.8%;
    left: 53.7%;
    width: 40px;
    height: 40px;
    display: block;
  }
  .apple {
    position: absolute;
    left: 43%;
    bottom: 56px;
    width: 70px;
    height: 30px;
  }
  .google {
    position: absolute;
    left: 53%;
    bottom: 56px;
    width: 70px;
    height: 30px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .content-box {
    position: relative;
    width: 100%;
    height: auto;
  }
  .main-fb {

    position: absolute;
    left: 37%;
    top: 28.9%;
    width: 70px;
    height: 70px;
    display: block;
  }
  .main-FB {
    position: absolute;
    bottom: 13%;
    left: 41%;
    width: 50px;
    height: 50px;
    display: block;
  }
  .main-IG {
    position: absolute;
    bottom: 13%;
    left: 47.5%;
    width: 50px;
    height: 50px;
    display: block;
  }
  .main-YT {
    position: absolute;
    bottom: 13%;
    left: 53.7%;
    width: 50px;
    height: 50px;
    display: block;
  }
  .apple {
    position: absolute;
    left: 43.2%;
    bottom: 77px;
    width: 93px;
    height: 35px;
  }
  .google {
    position: absolute;
    left: 53.2%;
    bottom: 77px;
    width: 93px;
    height: 35px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .content-box {
    position: relative;
    width: 100%;
    height: auto;
  }
  .main-fb {
    position: absolute;
    left: 37%;
    top: 29%;
    width: 100px;
    height: 100px;
    display: block;
  }
  .main-FB {
    position: absolute;
    bottom: 13%;
    left: 41.3%;
    width: 75px;
    height: 65px;
    display: block;
  }
  .main-IG {
    position: absolute;
    bottom: 13%;
    left: 47.5%;
    width: 75px;
    height: 65px;
    display: block;
  }
  .main-YT {
    position: absolute;
    bottom: 13%;
    left: 53.7%;
    width: 75px;
    height: 65px;
    display: block;
  }
  .apple {
    position: absolute;
    left: 43.2%;
    bottom: 103px;
    width: 130px;
    height: 48px;
  }
  .google {
    position: absolute;
    left: 53.2%;
    bottom: 103px;
    width: 130px;
    height: 48px;
  }
}
@media screen and (min-width: 1930px) {
  .content-box {
    position: relative;
    width: 100%;
    height: auto;
  }
  .main-fb {
    position: absolute;
    left: 37%;
    top: 29%;
    width: 175px;
    height: 175px;
  }
  .main-box,
  .main-box2 {
    position: relative;
  }
  .main-FB {
    position: absolute;
    left: 42%;
    bottom: 714px;
    width: 100px;
    height: 100px;
  }
  .main-IG {
    position: absolute;
    left: 48%;
    bottom: 714px;
    width: 100px;
    height: 100px;
  }
  .main-YT {
    position: absolute;
    left: 54%;
    bottom: 714px;
    width: 100px;
    height: 100px;
  }
  .apple {
    position: absolute;
    left: 43%;
    bottom: 197px;
    width: 233px;
    height: 72px;
  }
  .google {
    position: absolute;
    left: 53%;
    bottom: 197px;
    width: 233px;
    height: 72px;
  }
}
</style>